import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles, Container, useMediaQuery } from '@material-ui/core';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { TechSupportBody } from '../components/TechSupport/TechSupportBody';
import { TechSupportExternalContactForm } from '../components/TechSupport/TechSupportContactForm';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	formCont: {
		padding: '7rem 0 24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '10rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

const TechSupport = ({ data, location }) => {
	const techSupport = data.techSupport.edges[0].node;
	const classes = useStyles();

	const {
		hero,
		metaDescription,
		metaTitle,
		pardotUrl,
		contactForm,
		bodyHeader,
		techSupportCards,
		serviceSectionBg,
		_rawServiceSectionContent,
		formBgImage,
		externalForm,
	} = techSupport;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<TechSupportBody
				header={bodyHeader}
				techSupportCards={techSupportCards}
				serviceSectionBg={serviceSectionBg}
				serviceSectionContent={_rawServiceSectionContent}
			/>
			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<TechSupportExternalContactForm
					formValues={externalForm}
					contactForm={contactForm}
					// _rawFormFooterBody={_rawFormFooterBody}
					endPoint='https://workwave.my.salesforce-sites.com/web2case/services/apexrest/cases/create'
				/>
				<Form
					privacy
					modal={false}
					noForm={true}
					location={location}
					pardotUrl={pardotUrl}
				/>
			</div>
			{/* <Container>
				<div className={classes.formCont}>
					<Form
						pardotUrl={pardotUrl}
						contactForm={contactForm}
						privacy
						modal={false}
						location={location}
					/>
				</div>
			</Container> */}
			<div style={{ marginTop: '-6rem' }}>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query TechSupportQuery {
		techSupport: allSanityTechSupport {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						_rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED, height: 100)
							}
						}
						mobileBackgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaButtonText
						ctaButtonLink
						internalLink
					}
					heroImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					heroVideo
					heroAlignCenter
					resourceTitle
					bodyHeader
					techSupportCards {
						title
						icon
						header
						ctaText
						link
						ctaText2
						link2
						_rawBody
						accentColor {
							hexValue
						}
					}
					serviceSectionBg {
						asset {
							gatsbyImageData
						}
					}
					_rawServiceSectionContent
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
					contactForm {
						header
						privacyPolicy
					}
					formBgImage {
						asset {
							gatsbyImageData
						}
					}
					externalForm {
						fieldLabel
						fieldValue
						textArea
						externalFormSelect {
							fieldLabel
							fieldValue
						}
						conditionalLogic
            sa4
						sa5
						ama
						caw
            mol
						rgmp
            communities
            wwpayments
            wwuniversity
            wwforms
            wwanalytics
						attachment
						required
					}
					pardotUrl
				}
			}
		}
	}
`;

export default TechSupport;
