import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import PortableText from 'react-portable-text';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		minHeight: '375px',
		maxHeight: '400px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		position: 'relative',
		width: '585px',
		marginBottom: '2rem',
		[theme.breakpoints.down('md')]: {
			maxWidth: '500px',
			minHeight: '400px',
		},
		[theme.breakpoints.down('xs')]: {
			maxWidth: '90vw',
			minHeight: '500px',
		},
		transition: 'transform 0.25s',
	},
	title: {
		fontSize: '1.25rem',
		fontWeight: 600,
		textAlign: 'left',
		color: '#183B56',
		marginLeft: '.5rem',
		lineHeight: 1.3,
		[theme.breakpoints.down('xs')]: {
			marginLeft: '1rem',
		},
	},
	pos: {
		// textAlign: 'center',
		lineHeight: '28px',
		fontWeight: 500,
	},
	discount: {
		borderRadius: '10px 10px 0px 0px',
		minHeight: '60px',
		width: '250px',
		// [theme.breakpoints.down('md')]: {
		// 	maxWidth: '35%',
		// },
	},
	cardBody: {
		marginTop: '16px',
		height: '100%',
		[theme.breakpoints.down('md')]: {
			minHeight: '90px',
		},
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
	link: {
		fontSize: 16,
		marginTop: '15px',
		fontWeight: 'bold',
		color: theme.workwaveBlue,
		textAlign: 'center',
		width: '80%',
		'&:hover': {
			'& $arrow': { transform: 'translateX(10px)' },
		},
	},
	linkContainer: {
		bottom: 30,
		position: 'absolute',
		[theme.breakpoints.down('md')]: {
			position: 'relative',
			bottom: 'unset',
		},
	},
}));

export const TechSupportCard = ({ card, index }) => {
	const classes = useStyles();
	const {
		title,
		header,
		_rawBody,
		icon,
		accentColor,
		ctaText,
		link,
		ctaText2,
		link2,
	} = card;
	const md = useMediaQuery('(max-width: 960px)');

	return (
		<Grid
			item
			xs={12}
			md={6}
			container
			direction='column'
			justifyContent='center'
			alignItems='center'>
			<Card
				className={classes.root}
				elevation={0}
				style={{
					minHeight:
						index > 1 && !md ? '325px' : index !== 1 && md ? '350px' : null,
				}}>
				<Grid
					container
					alignItems='center'
					justifyContent='flex-start'
					style={{
						top: 0,
						borderTop: `16px solid ${card.accentColor.hexValue ?? '#002D5C'}`,
						background: card.accentColor.hexValue,
					}}></Grid>
				<CardContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '24px',
						alignItems: 'space-evenly',
						justifyContent: 'space-between',
						height: '100%',
					}}>
					<Grid
						item
						container
						direction='row'
						justifyContent='center'
						alignItems='center'>
						<FontAwesomeIcon
							icon={['fad', icon]}
							style={{
								color: accentColor.hexValue ?? '#002D5C',
								height: '60px',
								width: '60px',
								border: `1px solid #E5EAF4`,
								borderRadius: '8px',
								background: '#E5EAF4',
								padding: '10px',
								textAlign: 'center',
								marginBottom: '1rem',
							}}
						/>
					</Grid>
					<Grid
						item
						container
						direction='row'
						justifyContent='center'
						alignItems='center'>
						<Typography
							variant='h4'
							color='primary'
							style={{ fontWeight: 700 }}>
							{header}
						</Typography>
					</Grid>
					<Grid
						container
						item
						direction='row'
						justifyContent='flex-start'
						alignItems='flex-start'
						className={classes.cardBody}>
						<PortableText
							content={_rawBody}
							serializers={{
								normal: ({ children }) => (
									<Typography variant='body1' className={classes.pos}>
										{children}
									</Typography>
								),
							}}
						/>
					</Grid>
					<Grid container direction='column' className={classes.linkContainer}>
						<Typography variant='body2'>
							<a
								href={link}
								className={classes.link}
								target={index === 3 ? '_self' : '_blank'}>
								{ctaText}
								<span className={classes.arrow}>→</span>
							</a>
						</Typography>
						{ctaText2 ? (
							<Typography variant='body2' style={{ marginTop: '10px' }}>
								<a href={link2} className={classes.link} target='_blank'>
									{ctaText2}
									<span className={classes.arrow}>→</span>
								</a>
							</Typography>
						) : null}
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
